<template>
	<div class="work-detail">
		<banner-style1 :position="4"></banner-style1>
		<div class="work-detail-con c w">
			<el-breadcrumb separator=">">
				<el-breadcrumb-item :to="{ path: '/work/index' }">作品展示</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/work/index' }" v-if="1">{{detail.sessionNumberName}}
				</el-breadcrumb-item>
				<el-breadcrumb-item v-if="detail.title">{{detail.title}}</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="work-content">
				<div class="desc-warp">
					<h3 class="name">{{detail.title}}</h3>
					<p class="desc-ch">{{detail.describe}}</p>
					<p class="desc-en">{{detail.enTitle}}</p>
				</div>
				<div class="img-prev">
					<p v-html="detail.content"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		getContentInfo,
	} from '@/api/competition'
	import {
		useRoute
	} from 'vue-router'
	import {
		ref
	} from 'vue'
	const route = useRoute()
	const detail = ref('')
	getContentInfo({
		id: route.query.id
	}).then(res => {
		console.log(res);
		detail.value = res.data
	})
</script>

<style lang="scss" scoped>
	.el-breadcrumb {
		font-size: 22px;
		padding: 30px 0;
	}

	.el-breadcrumb__separator {
		color: #000;
	}

	.work-content {
		display: flex;
		justify-content: space-between;
		color: #595757;
		padding-bottom: 80px;

		.desc-warp {
			width: 210px;

			.name {
				color: #000;
			}
		}

		.desc-ch {
			margin: 20px 0;
		}

		.desc-en {
			word-break: keep-all;
			font-size: 12px;
		}

		.author {
			color: #595757;
			margin-top: 80px;
		}

		.img-prev {
			margin-bottom: 80px;
			width: 950px;

			.img-item {
				margin-bottom: 10px;

				.el-image {
					width: 800px;
				}
			}
		}
	}
</style>
